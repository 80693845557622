@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: never;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	overflow-x: hidden;
}

.fslightboxs {
	border-radius: 1.25rem;
}

.fslightbox-slide-btn {
	border-radius: 1.25rem;
	background: #05f !important;
}
@media (min-width: 1280px) {
	.fslightbox-slide-btn-container {
		padding: 30px !important;
	}
}

@layer base {
	@media only screen and (min-width: 2560px) {
		html {
			font-size: 150%;
		}
	}
	@media only screen and (max-width: 2559px) {
		html {
			font-size: 125%;
		}
	}
	@media only screen and (max-width: 1920px) {
		html {
			font-size: 105%;
		}
	}
	@media only screen and (max-width: 1600px) {
		html {
			font-size: 100%;
		}
	}
	@media only screen and (max-width: 1440px) {
		html {
			font-size: 90%;
		}
	}
	@media only screen and (max-width: 1280px) {
		html {
			font-size: 80%;
		}
	}
	@media only screen and (max-width: 900px) {
		html {
			font-size: 100%;
		}
	}
	@media only screen and (max-width: 600px) {
		html {
			font-size: 130%;
		}
	}
	@media only screen and (max-width: 520px) {
		html {
			font-size: 115%;
		}
	}
	@media only screen and (max-width: 440px) {
		html {
			font-size: 100%;
		}
	}
	@media only screen and (max-width: 410px) {
		html {
			font-size: 92%;
		}
	}
	@media only screen and (max-width: 375px) {
		html {
			font-size: 90%;
		}
	}
}

@keyframes pulse {
	from {
		transform: scale(100%);
	}
	25% {
		transform: scale(97%);
	}
	50% {
		transform: scale(100%);
	}
	75% {
		transform: scale(103%);
	}
	to {
		transform: scale(100%);
	}
}

@keyframes pulse-low {
	from {
		transform: scale(100%);
	}
	25% {
		transform: scale(98%);
	}
	50% {
		transform: scale(100%);
	}
	75% {
		transform: scale(102%);
	}
	to {
		transform: scale(100%);
	}
}

@keyframes pulse-opacity {
	from {
		opacity: 1;
	}

	50% {
		opacity: 0.4;
	}

	to {
		opacity: 1;
	}
}

.pulse {
	animation: pulse 1.5s linear infinite;
}

.pulse-low {
	animation: pulse-low 1.5s linear infinite;
}

.pulse-opacity {
	animation: pulse-opacity 1.5s linear infinite;
}
